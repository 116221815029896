<template>
  <div>
    <b-tabs content-class="mt-3" v-model="tabIndex" fill pills justified>
      <!-- info gps -->
      <b-tab class="grid-section" :title-link-class="linkClass(0)">
        <template #title>
          <div class="d-flex flex-column align-items-center">
            <i class="fa fa-circle-info" style="font-size: 2rem"></i>
            <strong>Información Gps</strong>
          </div>
        </template>
        <!-- gps -->
        <b-card class="m-0 position-relative">
          <!-- icon liberar gps -->
          <b-badge
            :variant="`${
              gps.gpsActivo && gps.gpsAsignado
                ? 'success'
                : gps.gpsActivo && !gps.gpsAsignado
                ? 'info'
                : 'danger'
            }`"
            class="position-absolute"
            style="top: 5px; right: 5px"
          >
            <i
              :class="`fas fa-${
                gps.gpsActivo && gps.gpsAsignado
                  ? 'circle-check'
                  : gps.gpsActivo && !gps.gpsAsignado
                  ? 'bolt'
                  : 'circle-xmark'
              }`"
            ></i>
            {{
              gps.gpsActivo && gps.gpsAsignado
                ? "Asignado"
                : gps.gpsActivo && !gps.gpsAsignado
                ? "Disponible"
                : "Inactivo"
            }}
          </b-badge>
          <!-- icon title -->
          <div class="card-title d-flex align-items-center">
            <i
              :class="`fa fa-location-dot ${
                gps.gpsActivo && gps.gpsAsignado
                  ? 'bg-success'
                  : gps.gpsActivo && !gps.gpsAsignado
                  ? 'bg-info'
                  : 'bg-danger'
              } rounded-circle d-flex align-items-center justify-content-center`"
              style="font-size: 1.5rem; width: 50px; height: 50px"
            ></i>
            <h3 class="font-weight-bold ml-2">Gps</h3>
          </div>
          <div class="d-flex flex-wrap" style="gap: 2rem">
            <!-- imei -->
            <div>
              <span class="text-white-50">Imei</span>
              <p class="h4 font-weight-bold">{{ gps.imei }}</p>
            </div>
            <!-- marca -->
            <div>
              <span class="text-white-50">Marca</span>
              <p class="h4 font-weight-bold">{{ gps.marca }}</p>
            </div>
            <!-- modelo -->
            <div>
              <span class="text-white-50">Modelo</span>
              <p class="h4 font-weight-bold">{{ gps.modelo }}</p>
            </div>
            <!-- serie -->
            <div>
              <span class="text-white-50">Serie</span>
              <p class="h4 font-weight-bold">{{ gps.serie }}</p>
            </div>
            <!-- fecha compra -->
            <div>
              <span class="text-white-50">Fecha compra</span>
              <p class="h4 font-weight-bold">
                {{ gps.fechaCompra | timeSince }}
              </p>
            </div>
          </div>
        </b-card>
        <!-- proveedor -->
        <b-card class="m-0">
          <!-- icon title -->
          <div class="card-title d-flex align-items-center">
            <i
              :class="`fa fa-boxes-packing  ${
                gps.gpsActivo && gps.gpsAsignado
                  ? 'bg-success'
                  : gps.gpsActivo && !gps.gpsAsignado
                  ? 'bg-info'
                  : 'bg-danger'
              } rounded-circle d-flex align-items-center justify-content-center`"
              style="font-size: 1.5rem; width: 50px; height: 50px"
            ></i>
            <h3 class="font-weight-bold ml-2">Proveedor</h3>
          </div>
          <div class="d-flex flex-wrap" style="gap: 2rem">
            <!-- nombre -->
            <div>
              <span class="text-white-50">Nombre</span>
              <p class="h4 font-weight-bold">{{ gps.proveedor }}</p>
            </div>
            <!-- direccion -->
            <div>
              <span class="text-white-50">Dirección</span>
              <p class="h4 font-weight-bold">{{ gps.marca }}</p>
            </div>
            <!-- telefono -->
            <div>
              <span class="text-white-50">Teléfono</span>
              <p class="h4 font-weight-bold">{{ gps.telefonoProveedor }}</p>
            </div>
            <!-- pais -->
            <div>
              <span class="text-white-50">País</span>
              <p class="h4 font-weight-bold">{{ gps.paisProveedor }}</p>
            </div>
            <!-- ciudad -->
            <div>
              <span class="text-white-50">Ciudad</span>
              <p class="h4 font-weight-bold">{{ gps.ciudadProveedor }}</p>
            </div>
          </div>
        </b-card>
        <!-- instalacion -->
        <b-card class="m-0">
          <!-- icon title -->
          <div class="card-title d-flex align-items-center">
            <i
              :class="`fa fa-screwdriver-wrench ${
                gps.gpsActivo && gps.gpsAsignado
                  ? 'bg-success'
                  : gps.gpsActivo && !gps.gpsAsignado
                  ? 'bg-info'
                  : 'bg-danger'
              } rounded-circle d-flex align-items-center justify-content-center`"
              style="font-size: 1.5rem; width: 50px; height: 50px"
            ></i>
            <h3 class="font-weight-bold ml-2">Instalación</h3>
          </div>
          <div class="d-flex flex-wrap" style="gap: 2rem">
            <!-- fecha -->
            <div>
              <span class="text-white-50">Fecha instalación</span>
              <p class="h4 font-weight-bold">
                {{ gps.fechaInstalacionGps | timeSince }}
              </p>
            </div>
            <!-- instalador -->
            <div>
              <span class="text-white-50">Instalador</span>
              <p class="h4 font-weight-bold">
                {{ gps.nombreCompletoInstalador }}
              </p>
            </div>
            <!-- ciudad -->
            <div>
              <span class="text-white-50">Ciudad</span>
              <p class="h4 font-weight-bold">{{ gps.ciudadInstalador }}</p>
            </div>
            <!-- direccion  -->
            <div>
              <span class="text-white-50">Dirección</span>
              <p class="h4 font-weight-bold">{{ gps.direccionInstalador }}</p>
            </div>
            <!-- telefono -->
            <div>
              <span class="text-white-50">Teléfono</span>
              <p class="h4 font-weight-bold">{{ gps.telefonoInstalador }}</p>
            </div>
          </div>
        </b-card>
        <!-- simCard -->
        <b-card class="m-0">
          <!-- icon title -->
          <div class="card-title d-flex align-items-center">
            <i
              :class="`fa fa-sim-card ${
                gps.gpsActivo && gps.gpsAsignado
                  ? 'bg-success'
                  : gps.gpsActivo && !gps.gpsAsignado
                  ? 'bg-info'
                  : 'bg-danger'
              } rounded-circle d-flex align-items-center justify-content-center`"
              style="font-size: 1.5rem; width: 50px; height: 50px"
            ></i>
            <h3 class="font-weight-bold ml-2">Sim Card</h3>
          </div>
          <div class="d-flex flex-wrap" style="gap: 2rem">
            <!-- numero  -->
            <div>
              <span class="text-white-50">Número</span>
              <p class="h4 font-weight-bold">{{ gps.numeroSimCard }}</p>
            </div>
            <!-- empresa -->
            <div>
              <span class="text-white-50">Empresa</span>
              <p class="h4 font-weight-bold">{{ gps.empresaSimCard }}</p>
            </div>
            <!-- fecha activacion -->
            <div>
              <span class="text-white-50">Fecha activación</span>
              <p class="h4 font-weight-bold">
                {{ gps.fechaActivacionSimCard | timeSince }}
              </p>
            </div>
            <!-- tipo plan -->
            <div>
              <span class="text-white-50">Tipo plan</span>
              <p class="h4 font-weight-bold">{{ gps.tipoPlanSimCard }}</p>
            </div>
          </div>
        </b-card>
      </b-tab>
      <!-- asignar vehiculo -->
      <b-tab :title-link-class="linkClass(1)">
        <template #title>
          <div class="d-flex flex-column align-items-center">
            <i class="fa fa-car" style="font-size: 2rem"></i>
            <strong>Asignar Gps a Vehículo</strong>
          </div>
        </template>
        <b-card class="m-0 position-relative">
          <div v-if="gps.gpsAsignado && gps.gpsActivo">
            <!-- icon liberar gps -->
            <vs-button
              :loading="isLiberating"
              flat
              class="position-absolute"
              style="top: 0; right: 0"
              @click="sendLiberateGps(gps)"
            >
              <i class="fas fa-bolt"></i>
              Liberar Gps
            </vs-button>
            <div class="d-flex align-items-center flex-wrap">
              <div>
                <h3 class="section-title card-title">Vehículo asignado</h3>
                <div class="d-flex flex-column">
                  <span>Placa</span>
                  <strong class="h3 font-weight-bold">{{
                    gps.placaVehiculo
                  }}</strong>
                </div>
              </div>
              <picture class="col-xl-6 d-flex justify-content-center">
                <img
                  loading="lazy"
                  width="100"
                  src="/img/gpsAsignadomapa.png"
                  alt="gps_inactivo"
                />
              </picture>
            </div>
          </div>
          <form
            v-if="!gps.gpsAsignado & gps.gpsActivo"
            @submit.prevent="assignGps"
          >
            <div class="row">
              <picture class="col-xl-6 d-flex justify-content-center">
                <img
                  loading="lazy"
                  width="150"
                  src="/img/gpsCamion.png"
                  alt="gps_inactivo"
                />
              </picture>
              <div class="col-xl-6">
                <div class="row h-100 align-content-between">
                  <!-- asignar a vehiculo -->
                  <div class="col-12">
                    <!-- vue-select -->
                    <div
                      style="
                        background: transparent;
                        padding: 0rem;
                       
                      "
                    >
                      <v-select
                        v-model="newAssignGps.vehiculoId"
                        class="style-chooser"
                        :reduce="(value) => value.id"
                        :options="listaVehiculos"
                        :get-option-label="(option) => `${option.placa}`"
                        placeholder="-- seleccionar vehículo --"
                      >
                        <!-- required -->
                        <template #search="{ attributes, events }">
                          <input
                            class="vs__search"
                            :required="!newAssignGps.vehiculoId"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                        <!-- sin resultados en la busquedad -->
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            No se encontraron resultados para
                            <em class="text-danger">{{ search }}</em
                            >.
                          </template>
                          <em v-else style="opacity: 0.5"
                            >No hay vehículos para mostrar.</em
                          >
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <!-- btn asignar gps -->
                  <div class="col-12">
                    <vs-button
                      :loading="isAssign"
                      type="submit"
                      class="w-100"
                      animation-type="vertical"
                      size="large"
                    >
                      Asignar
                      <template #animate> <i class="fas fa-car"></i></template>
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <template v-if="!gps.gpsActivo">
            <vs-alert danger>
              <template #icon>
                <i class="fas fa-warning"></i>
              </template>
              <template #title>Gps inactivo</template>
              El Gps debe estar activo para poder asignarlo a un vehículo!!
            </vs-alert>
            <picture class="d-flex justify-content-center">
              <img
                loading="lazy"
                width="150"
                src="/img/gpsInactivo.png"
                alt="gps_inactivo"
              />
            </picture>
          </template>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import moment_timezone from "moment-timezone";
const Swal = require("sweetalert2");
export default {
  props: {
    updateDataAssign: Function,
    updateDataLiberate: Function,
    gps: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        empresaId: null,
        imei: "",
        marca: "",
        modelo: "",
        serie: "",
        fechaCompra: "",
        proveedor: "",
        direccionProveedor: "",
        telefonoProveedor: "",
        paisProveedor: "",
        ciudadProveedor: "",
        fechaInstalacionGps: "",
        nombreCompletoInstalador: "",
        ciudadInstalador: "",
        direccionInstalador: "",
        telefonoInstalador: "",
        placaVehiculo: "",
        vehiculoId: null,
        gpsAsignado: false,
        gpsActivo: false,
        numeroSimCard: "",
        empresaSimCard: "",
        fechaActivacionSimCard: "",
        tipoPlanSimCard: "",
        ajusteHora: null,
        empresa: {
          /* Objeto de datos de la empresa */
        },
      }),
    },
  },
  data() {
    return {
      persona: {},
      tabIndex: 0,
      isAssign: false,
      isLiberating: false,
      newAssignGps: {
        gpsId: this.gps.id,
        placaVehiculo: "",
        vehiculoId: null,
      },
      listaVehiculos: [],
      tipoAccion: {
        liberar: "liberado",
        asignar: "asignado",
        crear: "creado",
        inactivar: "inactivado",
        activar: "activado",
      },
    };
  },
  async mounted() {
    // this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    if (this.gps.gpsActivo) {
      await this.getVehiculosByEmpresa(this.gps.empresaId);
    }
    // this.$isLoading(false);
  },
  methods: {
    getPlacaVehiculo(vehiculoId) {
      return this.listaVehiculos.find((v) => v.id === vehiculoId).placa;
    },
    async addLog(GpsId, PersonaId, VehiculoId, TipoAccion, Fecha) {
      try {
        let newLog = {
          GpsId,
          PersonaId,
          VehiculoId,
          TipoAccion,
          Fecha,
        };
        console.log(newLog, "newLog");
        let res = await this.$store.dispatch("hl_post", {
          path: "VehiculoGpsLog/",
          data: newLog,
        });
        console.log("add log...", res);
        if (res.success) {
          return res;
        }
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    async sendLiberateGps(gps) {
      Swal.fire({
        title: "Seguro de liberar?",
        text: "Éste Gps estará nuevamente disponible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "rgb(242, 19, 93)",
        confirmButtonText: "Si, liberar!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        console.log(result);
        if (result.isConfirmed) {
          const response = await this.liberateGps(gps.id);
          if (response.success) {
            // add log liberado
            await this.addLog(
              gps.id,
              this.persona.id,
              gps.vehiculoId,
              this.tipoAccion.liberar,
              this.$moment
                .tz(new Date(), "America/Bogota")
                .format("YYYY-MM-DDTHH:mm")
            );
            this.updateDataLiberate(gps.id);
            this.$vs.notification({
              //duration: "none",
              progress: "auto",
              icon: `<i class='fas fa-circle-check'></i>`,
              color: "primary",
              position: "top-right",
              title: "GPS Liberado!",
            });
          }
        }
      });
    },

    async liberateGps(gpsId) {
      try {
        this.isLiberating = true;
        let res = await this.$store.dispatch("hl_get", {
          path: "VehiculoRegistroImeiGPS/LiberateGps/" + gpsId,
        });
        console.log("liberateGps...", res);
        if (res.success) {
          this.isLiberating = false;
          return res;
        }
      } catch (error) {
        console.log("err", error);
        return null;
      }
    },
    async assignGps() {
      try {
        this.isAssign = true;
        let res = await this.$store.dispatch("hl_post", {
          path: "VehiculoRegistroImeiGPS/AssignCarGps/",
          data: {
            ...this.newAssignGps,
            placaVehiculo: this.getPlacaVehiculo(this.newAssignGps.vehiculoId),
          },
        });
        if (res.success) {
          const { gpsId, vehiculoId } = this.newAssignGps;
          // add log asignado
          await this.addLog(
            gpsId,
            this.persona.id,
            vehiculoId,
            this.tipoAccion.asignar,
            this.$moment
              .tz(new Date(), "America/Bogota")
              .format("YYYY-MM-DDTHH:mm")
          );
          this.updateDataAssign(
            gpsId,
            this.getPlacaVehiculo(vehiculoId),
            vehiculoId
          );
          this.isAssign = false;
          this.$vs.notification({
            //duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "GPS Asignado!",
          });
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async getVehiculosByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Vehiculo/VehiculosPorEmpresa/" + empresaId,
        });
        console.log("list vehiculos...", res);
        if (res.length > 0) {
          this.listaVehiculos = res;
        } else {
          this.listaVehiculos = [];
        }
      } catch (error) {
        this.listaVehiculos = [];
        console.log("err", error);
      }
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return [
          `${
            this.gps.gpsActivo && this.gps.gpsAsignado
              ? "bg-success"
              : this.gps.gpsActivo && !this.gps.gpsAsignado
              ? "bg-info"
              : "bg-danger"
          }`,
          "text-white",
          "p-3 mx-1 font-weight-bold h4",
        ];
      } else {
        return ["bg-light", "p-3 mx-1 h4"];
      }
    },
  },
  filters: {
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
.section-title {
  border-left: 5px solid #16a275;
  padding-left: 5px;
}
.grid-section {
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 8px;
}
</style>
